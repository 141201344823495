<template>
  <div class="home page">
    <div class="box">
      <!-- <div class="banner-img" /> -->
      <div class="header">
        <div class="search-tool">
          <router-link to="home" replace>
            <img class="logo" src="/logo.png" />
          </router-link>
          <router-link class="search-wrap" to="/h5/search">
            <!-- <div class="search-wrap" @click="$router.push('/h5/search')"> -->
            <img
              class="search-icon"
              src="@/assets/h5images/home/img_65757_0_6.png"
            />
            <span class="search-text">搜索族谱</span>
            <!-- </div> -->
          </router-link>
          <router-link to="user" replace>
            <img class="user" :src="userInfo.avatarImg" />
          </router-link>
        </div>
        <van-swipe class="banner-img" :autoplay="3000" indicator-color="white">
          <van-swipe-item
            v-for="item in banner"
            :key="item.id"
            class="banner-item"
          >
            <a v-if="item.url" :href="item.url">
              <img :src="item.image" alt="image" />
            </a>
            <img v-else :src="item.image" alt="image" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="middle-menu">
        <div class="menu-item" @click="handleToCate('family')">
          <img class="menu-icon" src="@/assets/h5images/home/zixun.png" />
          <span class="menu-label">家族资讯</span>
        </div>
        <div class="menu-item" @click="handleToCate('wenhua')">
          <img class="menu-icon" src="@/assets/h5images/home/minren.png" />
          <span class="menu-label">姓氏名人</span>
        </div>
        <div class="menu-item" @click="handleToCate('jingying')">
          <img class="menu-icon" src="@/assets/h5images/home/jinying.png" />
          <span class="menu-label">传承精英</span>
        </div>
        <div class="menu-item" @click="handleToCate('notice')">
          <img class="menu-icon" src="@/assets/h5images/home/gonggao.png" />
          <span class="menu-label">公告通知</span>
        </div>
        <router-link to="help">
          <div class="menu-item">
            <img class="menu-icon" src="@/assets/h5images/home/zhiyin.png" />
            <span class="menu-label">操作指引</span>
          </div>
        </router-link>
      </div>
      <div class="home-content">
        <div id="#zixun" class="block">
          <div class="block-title">
            家族资讯
          </div>
          <div v-loading="!data.image" class="block-content zixun">
            <!-- <div class="zixun-image"> -->
            <template v-if="data.image">
              <van-swipe
                class="zixun-image"
                :autoplay="3000"
                :show-indicators="false"
                @change="handleChange"
              >
                <van-swipe-item
                  v-for="item in data.image.articles"
                  :key="item.id"
                  class="zixun-item"
                >
                  <a v-if="item.url" :href="item.url">
                    <img :src="item.cover_image" alt="image" />
                  </a>
                  <img v-else :src="item.cover_image" alt="image" />
                  <div class="zixun-title">
                    {{ data.image.articles[activeIndex].title }}
                  </div>
                </van-swipe-item>
              </van-swipe>
              <!-- </div> -->
              <div class="zixun-content">
                <div
                  class="zixun-content-item"
                  v-for="item in data.family.articles"
                  :key="item.id"
                  @click="handleToArticle(item.id)"
                >
                  <span class="content">{{ item.title }}</span>
                  <span class="date"
                    >[{{ $format(item.cdatetime, "YYYY-MM-DD") }}]</span
                  >
                </div>
              </div>
            </template>
          </div>
        </div>
        <div id="#dongtai" class="block">
          <div class="block-title">
            寻根动态
          </div>
          <div class="block-content dongtai">
            <div class="dongtai-content">
              <div
                class="dongtai-content-item"
                v-for="item in eventList"
                :key="item.id"
                @click="$router.push(`/h5/events/${item.id}`)"
              >
                <span class="content">{{ item.title }}</span>
                <!-- <span class="date"
                  >[{{ $format(item.cdatetime, "YYYY-MM-DD") }}]</span
                > -->
              </div>
            </div>
          </div>
        </div>
        <div id="#about" class="block">
          <div class="block-title">
            关于我们
          </div>
          <div class="block-content about">
            <p class="about-text">
              名门家谱介绍
            <br /><br />
            “名门家谱”由曾子文化、家谱数字化倡导者刘鑫和数十位专业从事修谱服务10年以上的修谱专家共同发起组建。专业从事互联网修谱服务（纸网谱同修）、修谱软件和家族数字云平台的研发与销售，为各个姓氏家谱数字化、姓氏内容产品开发、家族文化的建设、宗亲凝聚和家谱有序实名制数据应用提供综合解决方案。名门家谱科技现对外直接投资元未来科技、乐和旅居、苗向缘文化等。
            <br /><br />
            名门家谱收集了1949年以前的老谱资料二十五万余册，覆盖500多个姓氏，补齐各姓氏修谱无老谱查询的短板，解决家族寻根查谱四处奔波的痛点，让寻根查谱的花费节省80%以上，为各个姓氏家族提供所有老谱数据输出服务，支持各个姓氏自建寻根查谱网络平台；同时名门家谱联合文化领域专家人才资源、图书馆和文旅服务等机构，为各个姓氏家族的家族文化建设与梳理、寻根文旅线路设计、姓氏文创产品设计与生产，提供培训及点对点支持服务，形成了以家谱数字化为引导、家族内容产品设计和生产为依托、家族寻根文旅活动为交互的立体家族文化建设与传播矩阵。 
            <br /><br />
            截止到2021年底，名门家谱在全国开设县级业务分公司21家，合作设立家族数字云平台15个，在线注册修谱师1300人，服务30000多个家族。 
            <br /><br />
            名门家谱数字云平台有以下特点：
            <br /><br />
            简单：操作简单，用手机就可以修谱、查谱、寻根以及与亲友交流。
            <br /><br />
            智能：平台拥有强大的搜索功能，知道连续两代人的信息，就可以精准匹配搜索，寻根查谱，输入字辈、自己的姓名等，就可以全平台模糊匹配搜索查谱；同时，平台具备智能排版功能、直系谱、家庭谱、房谱、支谱、族谱等可以一键生成、自动排版导出可印刷文件；支持数据调用，无论是素材还是家谱数据，都支持一键调用，让修谱成本降低80%，修谱效率提升百倍以上。不同家谱之间连续两带人同名同姓同出生日期系统会自动提示合谱，各支各房完成支房谱的同时，族谱，甚至通谱就基本形成了。
            <br /><br />
            便捷:有新增人丁可以随时上谱，发现信息疏漏可以及时修正，只要有网络就可以查谱、寻根与亲友交流。个人重要时刻可以随手记录，支持一键导出个人年谱。个人有什么需求可以直接分享到家族圈，可以第一时间获得家人的支持！
            </p>
            <!-- <div class="about-text">
              企业使命：家风传承 谱泽后世
            </div>
            <p class="about-text">
              企业愿景：让优秀家族文化插上互联网的翅膀
            </p>
            <p class="about-text">
              企业宗旨：专注谱学 传承姓氏文化
            </p>
            <p class="about-text">
              企业目标：让每个家庭都拥有属于自己的历史
            </p>
            <p class="about-text">
              企业口号：让传统家谱活起来
            </p> -->
          </div>
        </div>
        <div class="block">
          <div class="block-title">
            主营业务
          </div>
          <div class="block-content business">
            <div class="business-item">
              <div class="business-image">
                <img src="@/assets/h5images/home/bu1.png" alt="" />
              </div>
              <div class="business-content">
                <div class="business-content-title">修谱服务</div>
                <div class="business-content-desc">
                  纸质谱、网络谱、电子谱 同修服务
                </div>
              </div>
            </div>
            <div class="business-item">
              <div class="business-image">
                <img src="@/assets/h5images/home/bu2.png" alt="" />
              </div>
              <div class="business-content">
                <div class="business-content-title">寻根溯源</div>
                <div class="business-content-desc">
                  谱数据寻根及悬赏寻根
                </div>
              </div>
            </div>
            <div class="business-item">
              <div class="business-image">
                <img src="@/assets/h5images/home/bu3.png" alt="" />
              </div>
              <div class="business-content">
                <div class="business-content-title">传记代写</div>
                <div class="business-content-desc">
                  私人定制，代写个人及家族传记服务
                </div>
              </div>
            </div>
            <div class="business-item">
              <div class="business-image">
                <img src="@/assets/h5images/home/bu4.png" alt="" />
              </div>
              <div class="business-content">
                <div class="business-content-title">文化传播</div>
                <div class="business-content-desc">
                  姓氏文化及祠堂文化提炼与传播
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block">
          <div class="block-title">
            联系方法
          </div>
          <div class="block-content contact">
            <div class="contact-block">
              <div class="line">
                <span class="left-text">联系电话：</span>
                <span class="right-text">13617482511</span>
              </div>
              <div class="line">
                <span class="left-text">联系地址：</span>
                <span class="right-text">迎宾路38号二楼</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import otherApis from "@/apis/other";
import indexApis from "@/apis/index";
import { Swipe, SwipeItem } from "vant";
import { mapState } from "vuex";

Vue.use(Swipe);
Vue.use(SwipeItem);

export default {
  name: "Home",
  data() {
    return {
      banner: [],
      aboutImgSrc: "",
      data: [],
      activeIndex: 0,
      eventList: []
    };
  },
  computed: {
    ...mapState(["userInfo"])
  },
  created() {
    this.fetchBanner();
    this.fetchList();
    this.fetchEvent();
  },
  methods: {
    async fetchBanner() {
      console.log("fetch banner");
      const {
        data: { banners }
      } = await otherApis.banner();

      // const {
      //   data: { img }
      // } = await otherApis.articleAbout();
      this.banner = banners;
      // this.aboutImgSrc = img;
      // console.log(banners);
    },
    async fetchEvent() {
      const {
        data: { article_list }
      } = await otherApis.articleIndex();

      this.eventList = article_list;
    },
    async fetchList() {
      this.loading = true;
      try {
        const {
          data: { result }
        } = await indexApis.indexGroup({
          cat_key:
            "image, family, notice, wenhua, junshi, keji, shangjie, zhengtan,  finance, jingying, download"
        });
        this.data = result;
      } catch (error) {
        // this.finished = true;
        this.error = true;
        console.error(error);
      }
      this.loading = false;
    },
    handleChange(index) {
      this.activeIndex = index;
    },
    handleToArticle(id) {
      this.$router.push(`/h5/article?id=${id}`);
    },
    handleToCate(key) {
      const id = this.data?.[key]?.id;
      if (!id) return;
      this.$router.push(`/h5/article?cat_id=${id}`);
      // this.init();
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  width: 1125px;
  // height: 4990px;
  // padding-bottom: 100px;
  background-color: #ffffff;
}

.box {
  background-size: contain;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  background-color: #f7f7f7;
  // background-image: url(./images/img_65757_1_0.png);
  // background: linear-gradient(90deg, #e15f23 0%, #d24b2b 100%);
  overflow: hidden;
}

.header {
  display: flex;
  // position: absolute;
  // top: 0;
  align-items: center;
  align-self: center;
  flex-direction: column;
  // background-color: #CA0A15;
  width: 1125px;
  height: 700px;
  background: linear-gradient(90deg, #e15f23 0%, #d24b2b 100%);
  overflow: hidden;
  padding-top: env(safe-area-inset-top, 0);
}

.search-tool {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  // margin-top: 23px;
  padding: 37px 0;
  width: 100%;
  z-index: 100;
  justify-content: center;
  // background-color: #fff;
}

.logo {
  margin-right: 39px;
  width: 125px;
  height: 120px;
}

.search-wrap {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: 39px;
  border-radius: 50px;
  background-color: #fdffff;
  width: 750px;
  height: 100px;
  overflow: hidden;
}

.search-icon {
  margin-right: 31px;
  margin-left: 36px;
  width: 51px;
  height: 50px;
}

.search-text {
  margin-top: -2px;
  text-decoration: none;
  line-height: 36px;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #666666;
  font-size: 44px;
}

.user {
  width: 90px;
  height: 90px;
}

.banner-img {
  // position: absolute;
  // left: 0;
  // top: 0;
  width: 1125px;
  height: 424px;

  .banner-item {
    height: 100%;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.middle-menu {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #ffffff;
  width: 1125px;
  height: 320px;
  overflow: hidden;
}

.menu-item {
  display: flex;
  align-items: center;
  flex-direction: column;

  .menu-icon {
    position: relative;
    // width: 133px;
    height: 120px;
    object-fit: contain;
  }

  .menu-label {
    position: relative;
    margin-top: 39px;
    text-decoration: none;
    line-height: 36px;
    letter-spacing: 0px;
    white-space: nowrap;
    color: #666666;
    font-size: 44px;
  }
}

.home-content {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 30px;
  padding: 0 40px;
  box-sizing: border-box;
  background-color: #ffffff;
  width: 1125px;
  overflow: hidden;

  .block {
    width: 100%;
    border-bottom: 1px solid #efefef;
    margin-top: 60px;
    padding-bottom: 60px;

    .block-title {
      text-align: center;
      color: #8c4134;
      font-size: 60px;
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        content: "";
        display: block;
        margin-right: 34px;
        background-color: #ca0a15;
        width: 50px;
        height: 6px;
        overflow: hidden;
      }

      &::after {
        content: "";
        display: block;
        margin-left: 34px;
        background-color: #ca0a15;
        width: 50px;
        height: 6px;
        overflow: hidden;
      }
    }

    .block-content {
    }
  }

  .zixun {
    .zixun-image {
      margin-top: 60px;
      width: 1065px;
      height: 600px;
      border-radius: 10px 10px 0px 0px;
      overflow: hidden;

      .zixun-item {
        height: 100%;
        width: 100%;
        position: relative;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        .zixun-title {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 90px;
          line-height: 90px;
          padding: 0 32px;
          box-sizing: border-box;
          color: #ffffff;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 36px;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.3);
        }
      }
    }

    .zixun-content {
      font-size: 36px;
      color: #333333;
      .zixun-content-item {
        margin-top: 40px;
        white-space: nowrap;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .content {
          display: inline-block;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;

          &::before {
            content: "‧";
            line-height: 32px;
            display: inline-block;
          }
        }

        .date {
          color: #8f8f8f;
          float: right;
        }
      }
    }
  }

  .dongtai {
    .dongtai-content {
      font-size: 36px;
      color: #333333;
      .dongtai-content-item {
        margin-top: 40px;
        white-space: nowrap;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .content {
          display: inline-block;
          width: calc(100% - 56px);
          overflow: hidden;
          text-overflow: ellipsis;

          &::before {
            content: "‧";
            line-height: 32px;
            display: inline-block;
          }
        }

        .date {
          color: #8f8f8f;
          float: right;
        }
      }
    }
  }

  .about {
    .about-image {
      position: relative;
      align-self: center;
      margin-top: 62px;
      border-radius: 10px;
      background-color: #fdbd9b;
      width: 1044px;
      height: 700px;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .about-text {
      color: #333333;
      line-height: 72px;
      font-size: 44px;
      text-indent: 2em;
    }
  }

  .business {
    .business-item {
      display: flex;
      position: relative;
      align-items: center;
      align-self: center;
      flex-direction: row;
      justify-content: center;
      margin-top: 40px;
      background-color: #f7f7f7;
      width: 1044px;
      height: 360px;
      overflow: hidden;
      padding: 0 40px;
      box-sizing: border-box;

      &:first-of-type {
        margin-top: 65px;
      }

      .business-image {
        margin-right: 41px;
        border-radius: 10px;
        background-color: #937df7;
        width: 280px;
        height: 280px;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .business-content {
        flex: 1;

        &-title {
          white-space: nowrap;
          color: #333333;
          font-size: 56px;
        }

        &-desc {
          color: #666666;
          font-size: 44px;
          margin-top: 52px;
          line-height: 60px;
          white-space: normal;
        }
      }
    }
  }

  .contact {
    margin-bottom: 40px;
    .contact-block {
      display: flex;
      position: relative;
      align-items: center;
      align-self: center;
      flex-direction: column;
      margin-top: 76px;
      background-color: #f7f7f7;
      width: 1044px;
      height: 260px;
      overflow: hidden;
      padding: 0 40px;
      box-sizing: border-box;

      .line {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 44px;

        .left-text {
          color: #333333;
        }

        .right-text {
          color: #666666;
        }
      }
    }
  }
}
</style>
